import React from "react";
import { notification } from "antd";

export default (err) => {
  if (err.response) {
    const { response } = err;
    const { statusCode, body } = response;

    if (statusCode === 422) {
      const arrayOfError = [];
      body.errors.map((err2) => arrayOfError.push(err2.msg));
      notification.error({
        message: body.msg,
        description: (
          <ul>
            {arrayOfError.map((err2) => (
              <li>{err2}</li>
            ))}
          </ul>
        ),
        placement: "bottomRight",
      });
    } else if (statusCode === 401) {
      notification.error({
        message: body.error,
        placement: "bottomRight",
      });
    } else if (statusCode === 400) {
      notification.error({
        message: "Sorry..",
        description: body.error,
        placement: "bottomRight",
      });
    } else if (statusCode === 403) {
      notification.warning({
        message: "Sorry..",
        description: body.error,
        placement: "bottomRight",
      });
    } else if (statusCode === 404) {
      notification.error({
        message: "404 not found!",
        // description: i18n.t(body.data),
        placement: "bottomRight",
      });
    }
  } else {
    notification.error({
      message: "Sorry..",
      description: "Server Error",
      placement: "bottomRight",
    });
  }
};
