import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from './locales/en.json';
import ar from './locales/ar.json';
import ku from './locales/ku.json';

i18n
  // detect user language
  .use(initReactI18next)

  // pass the i18n instance to react-i18next.
  .use(LanguageDetector)

  // init i18next
  .init({
    debug: false,
    fallbackLng: 'en',
    lng: localStorage.getItem('i18nextLng') || 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en,
      ar,
      ku,
    },
  });

export default i18n;
