import React from 'react';
import { Row, Col } from 'antd';
import { observer, inject } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import Login from './Login';
import loginBg from '../../assets/images/login-bg.png';

@inject('userStore', 'tokenStore', 'sectionsStore')
@observer
class LoginManager extends React.Component {
  constructor() {
    super();
    this.initialState = () => ({});

    this.state = this.initialState();
  }

  render() {
    const { tokenStore, to } = this.props;

    if (`${tokenStore.value}`.trim() !== '') {
      return to;
    }
    return (
      <div className="login-bg" style={{ backgroundImage: `url(${loginBg})` }}>
        <Row justify="center" align="middle" style={{ height: '100vh' }}>
          <Col span={8}>
            <Login />
          </Col>
        </Row>
      </div>
    );
  }
}

export default withTranslation()(LoginManager);
