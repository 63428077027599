/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable quotes */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable indent */
/* eslint-disable no-confusing-arrow */
/* eslint-disable no-return-assign */
/* eslint-disable function-paren-newline */
/* eslint-disable operator-linebreak */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-return-assign */
/* eslint-disable comma-dangle */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable object-curly-newline */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable quotes */

import React from "react";
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  notification,
  message,
  Card,
} from "antd";
import { observer, inject } from "mobx-react";
import _ from "lodash";
import { UserOutlined, LockFilled } from "@ant-design/icons";
import i18n from "../../i18n";
import getAgentInstance from "../../helpers/superagent";
import logo from "../../assets/images/tornet.png";

const superagent = getAgentInstance();

@inject("userStore", "sectionsStore", "tokenStore")
@observer
class Login extends React.Component {
  constructor(props) {
    super(props);
    this.initialState = () => ({
      loading: false,
      disableLogBtn: false,
    });
    this.state = this.initialState();
    this.onFinish = (values) => {
      this.setState({ loading: true });
      superagent
        .post("/auth/login")
        .send(values)
        .end((err, res) => {
          if (err) {
            notification.error({ content: i18n.t("Login Failed!") });
          } else {
            const { body } = res;
            const { tokenStore, userStore } = this.props;
            if (body.token) {
              userStore.value = body.data;
              tokenStore.value = body.token;
              window.location.reload();
              message.success({ content: i18n.t("Successfully logged in") });
            } else {
              message.error({ content: body.message });
            }
          }
          this.setState({ loading: false });
        });
    };

    this.onValuesChange = _.debounce((changedVal) => {
      if (changedVal.password && changedVal.password.length >= 6) {
        this.setState({ disableLogBtn: false });
      } else this.setState({ disableLogBtn: true });
    }, 500);
  }

  componentDidMount() {
    if (i18n.language === "en-US") {
      i18n.changeLanguage("en");
      window.location.reload();
    }
  }

  render() {
    const { loading, disableLogBtn } = this.state;
    return (
      <>
        <Card className="login-card font-calss">
          <Form
            onFinish={this.onFinish}
            layout="vertical"
            onValuesChange={this.onValuesChange}
          >
            <Row justify="center" gutter={[0, 10]}>
              <Col>
                <p className="login-card-title font-calss">
                  <img
                    src={logo}
                    alt="logo"
                    style={{ width: "400px", height: "140px" }}
                  />
                </p>
              </Col>
            </Row>
            <Row justify="center" gutter={[0, 64]}>
              <Col>
                <p className="login-card-subtitle font-calss">
                  {i18n.t("TORNET COMPANY | ")}{" "}
                  <span style={{ color: "#FF5B00" }}>
                    {i18n.t("| TORNET DIGITAL ZONE")}
                  </span>
                </p>
              </Col>
              <Col>
                <p className="login-card-subtitle font-calss">
                  {i18n.t(
                    "Access And Manage TDZ Service From This Admin Panel"
                  )}
                </p>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="username"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input
                    style={{ borderRadius: "20px", height: "50px" }}
                    className="login_Input"
                    placeholder={i18n.t("Username")}
                    prefix={
                      <UserOutlined
                        style={{ fontSize: "20px", paddingLeft: "10px" }}
                      />
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[0, 24]}>
              <Col span={24}>
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input.Password
                    style={{ borderRadius: "20px", height: "50px" }}
                    className="login_Input"
                    placeholder={i18n.t("Password")}
                    prefix={
                      <LockFilled
                        style={{ fontSize: "20px", paddingLeft: "10px" }}
                      />
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col offset={7} span={10}>
                <Button
                  block
                  size="large"
                  style={{ height: "45px", borderRadius: "20px" }}
                  className="font-calss"
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                  disabled={disableLogBtn}
                >
                  {" "}
                  {i18n.t("Login")}
                </Button>
              </Col>
            </Row>
            <br />
            <Row justify="center">
              <Col>
                <p className="login-card-subtitle font-calss">
                  {i18n.t("Developed By")}{" "}
                  <a
                    className="font-calss"
                    target="_blank"
                    rel="noreferrer"
                    href="http://www.tornet.co/"
                    style={{ textDecoration: "underline" }}
                  >
                    {i18n.t("Tornet Co.")}
                  </a>
                </p>
              </Col>
            </Row>
          </Form>
        </Card>
      </>
    );
  }
}
export default Login;
