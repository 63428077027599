/* eslint-disable quotes */
import { lazy } from "react"; // ela aw waxte calle aw componenta daka ka requeste lo dache

const options = [
  {
    key: Math.random(),
    path: '/',
    component: lazy(() => import('../dashboard/Main')),
    exact: true,
  },
  {
    key: Math.random(),
    path: "/user",
    component: lazy(() => import("../user/List")),
    exact: true,
  },
  {
    key: Math.random(),
    path: '/reports',
    component: lazy(() => import('../reports/List')),
    exact: true,
  },
  {
    key: Math.random(),
    path: "/proposal",
    component: lazy(() => import("../proposal/Main")),
    exact: true,
  },
  {
    key: Math.random(),
    path: "/services",
    component: lazy(() => import("../services/List")),
    exact: true,
  },
  {
    key: Math.random(),
    path: "/my-profile",
    component: lazy(() => import("../my-profile/Main")),
    exact: true,
  },
  {
    key: Math.random(),
    path: "/content",
    component: lazy(() => import("../content/List")),
    exact: true,
  },
  {
    key: Math.random(),
    path: "/users",
    component: lazy(() => import("../users/List")),
    exact: true,
  },
];
export default options;
