/* eslint-disable comma-dangle */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable object-curly-newline */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable quotes */
import React, { Suspense } from "react";
import { observer, inject } from "mobx-react";
import { Switch, Route, Link, withRouter } from "react-router-dom";
import {
  Layout,
  Menu,
  Button,
  Col,
  Modal as AntdModal,
  Avatar,
  Typography,
  Space,
  Dropdown,
} from "antd";
import {
  MenuOutlined,
  UserOutlined,
  FilePdfOutlined,
  PoweroffOutlined,
  UserAddOutlined,
  BarChartOutlined,
  TeamOutlined,
  FormOutlined,
  ShopOutlined,
  PieChartOutlined
} from "@ant-design/icons";
import Loading from "../basic/Loading";
import routes from "./dashboardRoutes";
import largeLogo from "../../assets/images/tornet.png";
import "./dashboard.css";
import getAgentInstance from "../../helpers/superagent";

const superagent = getAgentInstance();

const { Header, Content, Footer, Sider } = Layout;
const rootSubmenuKeys = ["/", "2", "6", "4"];
@inject("userStore", "tokenStore", "sectionsStore")
@observer
class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    const { userStore } = this.props;
    this.initialState = () => ({
      collapsed: false,
      pic: "",
      windowsWidth: 0,
      defualtMenu: "visitlist",
      openKeys: [],
      access: JSON.parse(userStore.value.privilege),
    });
    this.state = this.initialState();

    this.toggleSidebar = () => {
      this.setState((prevState) => ({
        collapsed: !prevState.collapsed,
      }));
    };
    this.logout = () => {
      const { tokenStore, sectionsStore } = this.props;
      Promise.all([
        userStore.clear(),
        tokenStore.clear(),
        sectionsStore.clear(),
      ]).then(() => {});
    };
    this.warning = () => {
      const scope = this;
      AntdModal.confirm({
        centered: true,
        title: "Are You Sure",
        content: "You are abuot to logout of the system!",
        okText: "Logout",
        onOk() {
          scope.logout();
        },
        onCancel() {},
      });
    };
  }

  componentDidMount() {
    const { userStore } = this.props;
    if (userStore.value.privilege === undefined) {
      this.setState({ access: {} });
    }
    const scope = this;
    superagent.get("/user/myprofile").end((err, res) => {
      if (!err) {
        const { body } = res;
        userStore.value = body;
      } else if (err && err.status === 401) {
        AntdModal.error({
          centered: true,
          title: "Unauthorised!",
          content: "This account is unauthorised, You need to logout!",
          okText: "Procced",
          onOk() {
            scope.logout();
          },
        });
      }
    });
  }

  render() {
    const { collapsed, openKeys, access } = this.state;
    const { location, userStore } = this.props;
    const userName = userStore.value.full_name;
    const coverpic = `${process.env.REACT_APP_IMAGE_LINK}/${userStore.value.coverpic}`;
    const { history } = this.props;
    let removeBg;
    if (location && location.pathname === "/") {
      removeBg = true;
    } else removeBg = false;
    const menu = (
      <Menu style={{ top: "-10px" }}>
        <Menu.Item
          onClick={() => history.push("/my-profile")}
          key="/my-profile"
        >
          <TeamOutlined
            style={{
              color: "#FF5B00",
              paddingRight: "7px",
            }}
          />
          <span>My Profile</span>
        </Menu.Item>
        {access.View_Admins && (
          <Menu.Item onClick={() => history.push("/user")} key="/user">
            <UserAddOutlined
              style={{
                color: "#345dcf",
                paddingRight: "7px",
              }}
            />
            <span>User</span>
          </Menu.Item>
        )}
        <Menu.Divider />
        <Menu.Item
          onClick={this.warning}
          icon={
            <PoweroffOutlined
              style={{
                color: "#d16e17",
                paddingRight: "0.5px",
              }}
            />
          }
        >
          <span>Logout</span>
        </Menu.Item>
      </Menu>
    );
    const onOpenChange = (keys) => {
      const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
      if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
        this.setState({ openKeys: keys });
      } else {
        this.setState({
          openKeys: latestOpenKey ? [latestOpenKey] : [],
        });
      }
    };
    const sider = (
      <Sider
        theme="dark"
        style={{
          overflow: "auto",
          background: "#FFF",
        }}
        width={collapsed ? 0 : 240}
      >
        <div
          className="logo"
          style={{
            paddingTop: "15px",
            textAlign: "center",
            background: "#FFF",
          }}
        >
          <img alt="Tornet Logo" style={{ width: 150 }} src={largeLogo} />

          <p style={{ fontSize: "15px" }}>
            <span>TORNET</span>{" "}
            <span style={{ color: "#FF5B00" }}>DIGITAL ZONE</span>
          </p>
        </div>
        <Menu
          openKeys={openKeys}
          onOpenChange={onOpenChange}
          theme="white"
          selectedKeys={[location.pathname]}
          mode="inline"
          style={{ background: "#FFF", marginTop: 20 }}
        >
          {access.View_Dashboard && (
          <Menu.Item key="/" onClick={() => this.setState({ openKeys: [] })}>
            <BarChartOutlined style={{ paddingRight: '11px' }} />
            Dashboard
            <Link to="/" />
          </Menu.Item>
          )}
          {access.View_Services && (
            <Menu.Item
              style={{ backgroundColor: "#FFF" }}
              key="/services"
              onClick={() => this.setState({ openKeys: [] })}
            >
              <ShopOutlined style={{ paddingRight: "11px" }} />
              Services
              <Link to="/services" />
            </Menu.Item>
          )}
          {access.View_Contents && (
            <Menu.Item
              style={{ backgroundColor: "#FFF" }}
              key="/content"
              onClick={() => this.setState({ openKeys: [] })}
            >
              <FormOutlined style={{ paddingRight: "11px" }} />
              Contents
              <Link to="/content" />
            </Menu.Item>
          )}
          {access.View_Users && (
          <Menu.Item
            style={{ backgroundColor: "#FFF" }}
            key="/users"
            onClick={() => this.setState({ openKeys: [] })}
          >
            <UserOutlined style={{ paddingRight: "11px" }} />
            Users
            <Link to="/users" />
          </Menu.Item>
          )}
          <Menu.Item key="/reports" onClick={() => this.setState({ openKeys: [] })}>
            <PieChartOutlined style={{ paddingRight: '11px' }} />
            Reports
            <Link to="/reports" />
          </Menu.Item>
          <Menu.Item
            style={{ backgroundColor: "#FFF" }}
            key="/proposal"
            onClick={() => this.setState({ openKeys: [] })}
          >
            <FilePdfOutlined style={{ paddingRight: "11px" }} />
            About Tornet
            <Link to="/proposal" />
          </Menu.Item>
        </Menu>
      </Sider>
    );
    const header = (
      <Header
        className="site-layout-background"
        style={{ padding: "0 12px", display: "inherit" }}
      >
        <Col span={12} style={{ textAlign: "start" }}>
          <Button
            type="link"
            icon={<MenuOutlined style={{ fontSize: 15, color: "#1f2532" }} />}
            onClick={this.toggleSidebar}
          />
        </Col>
        <Col span={12} style={{ textAlign: "end" }}>
          <Space size="middle">
            <Dropdown overlay={menu} trigger="click">
              <Space>
                <Avatar size="small" src={coverpic} icon={<UserOutlined />} />
                <span
                  style={{
                    cursor: "pointer",
                    fontFamily: "Inter",
                    textTransform: "capitalize",
                  }}
                >
                  {userName?.toLowerCase()}
                </span>
              </Space>
            </Dropdown>
          </Space>
        </Col>
      </Header>
    );
    const content = (
      <Content style={{ margin: "16px 16px", marginBottom: 0 }}>
        <div
          className="site-layout-background"
          style={{
            minHeight: 500,
            padding: removeBg ? 0 : 24,
            background: removeBg ? "none" : undefined,
          }}
        >
          <Suspense fallback={<Loading />}>
            <Switch>
              {routes.map((route) => (
                <Route
                  exact={route.exact}
                  key={route.key}
                  path={`${route.path}`}
                >
                  <route.component />
                </Route>
              ))}
            </Switch>
          </Suspense>
        </div>
      </Content>
    );
    const footer = (
      <Footer style={{ textAlign: "center" }}>
        <Col span={24}>
          <Typography.Paragraph align="center" type="secondary">
            <span>Designed and developed by</span>
            <a
              target="_blank"
              rel="noreferrer"
              href="http://www.tornet.co"
              title="Tornet Co."
            >
              Tornet
            </a>
          </Typography.Paragraph>
        </Col>
      </Footer>
    );
    return (
      <>
        <Layout style={{ minHeight: "100vh" }}>
          {sider}
          <Layout
            className="site-layout"
            style={{
              transition: "all 0.2s",
            }}
          >
            {header}
            {content}
            {footer}
          </Layout>
        </Layout>
      </>
    );
  }
}
export default (
  withRouter((props) => <Dashboard {...props} />)
);
